/* 
  @media only screen and (min-width: 600px) {
    .dropdown:hover .dropdown-menu {
        display: block;
        margin: 0;
     }
     .nav-item{
      opacity: 0.9;
    }
} */
.navbar-nav > li{
  padding-left:10px;
  padding-right:10px;
}
svg:hover{
  opacity: 0.6;
 
}


ul{
  z-index: 2;
}

#schoologo{
  font-size:30px;
  font-weight:700;
  color: black!important;
 
}

#schoolname{
  width:120px;
  height:120px;
}

@media screen and (max-width: 768px) {
  #schoologo{
    font-size:15px;
    font-weight:700;
  }
  #schoolname{
    width:90px;
    height:105px;
  }
  #cbse{
  
    padding-top: 5px;
    font-size: 12px;
    font-weight:700;
  }
  .back{

    background-color:white;
    color:black;
  }
}

#cbse{
  align-self: start;
  justify-self: end;
  color: white;
  padding-top: 15px;
  /* font-size: 2vh; */
  font-weight:700;
}
.back{
  background-color:#170055;
  color:white;


}